.mainGalleryWrapper {
    column-count: 3;
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-width: 33%;
    -moz-column-width: 33%;
    -webkit-column-width: 33%;
    padding: 32px 12px;
    height: fit-content;
    padding: 20px;
    box-sizing: border-box;
}

.mainGalleryWrapper .pic {
    /* width: 100%;
    height: 50dvh; */
    /* border: 3px solid red; */
    cursor: pointer;
    transition: all .6s ease-in-out;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mainGalleryWrapper .pic > h2 {
    font-size: 2rem;
    font-weight: bold;
    text-transform: capitalize;
    text-align: center;
    letter-spacing: 1.3px;
}

.mainGalleryWrapper .pic:hover {

}

.mainGalleryWrapper .pic .mainImg {
    width: 100%;
    height: 100%;
}

.mainGalleryWrapper .pic .mainImg > img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 991px) {
    .mainGalleryWrapper {
        column-count: 2;
        -moz-column-count: 2;
        -webkit-column-count: 2;
    }
}
@media screen and (max-width: 480px) {
    .mainGalleryWrapper {
        column-count: 1;
        -moz-column-count: 1;
        -webkit-column-count: 1;
    }
}

.modal, .open {
    width: 100%;
    height: 100dvh;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out ;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;
}

.open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.modal > img,.open > img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0;
    margin: 0 auto;
    object-fit: contain;
}

.open .close {
    font-size: 2rem;
    font-weight: bold;
    color: red;
    position: absolute;
    top: 20px;
    right: 20px;
}