.contactCard {
    width: 100%;
    height: 30dvh;
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(var(--primary-color-60), var(--primary-color-60)), url(./../../assets/images/88339882_112961560310243_5820045440472580096_n.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.contactCard .contactCardWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.contactCard .contactCardWrapper .contactCardHeading {
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 1.3px;
    color: #fff;
    text-transform: capitalize;
    margin: 0;
    margin-bottom: 16px;
}

.contactCard .contactCardWrapper .contactCardBtn {
    padding: 16px 32px;
    box-sizing: border-box;
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 1.3px;
    text-transform: capitalize;
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    border-radius: 4px;
}

@media screen and (max-width: 1366px) {
    .contactCard {
        height: 40dvh;
        height: 40vh;
    }
}

@media screen and (max-width: 430px) {
    .contactCard {
        height: 40dvh;
        height: 40vh;
    }
    
    .contactCard .contactCardWrapper .contactCardHeading {
        text-align: center;
    }
}