.eventSection {
    width: 100%;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    box-sizing: 'border-box';
    background-color: var(--background-color);
}

.eventSection .eventSectionWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.eventSection .eventSectionWrapper .eventSectionHeading {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.eventSection .eventSectionWrapper .eventSectionHeading > h2 {
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 1.3px;
    margin: 0px 0px 8px 0%;
    padding: 0;
    color: var(--primary-color);
}

.eventSection .eventSectionWrapper .eventSectionHeading > p {
    font-size: 15px;
    margin: 0;
    width: 50%;
    text-align: center;
}

.eventSection .eventSectionWrapper .events {
    width: 100%;
    height: 80dvh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.eventSection .eventSectionWrapper .events .mySwiper {
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    /* display: flex; */
    /* align-items: center;
    justify-content: center; */
}

.eventSection .eventSectionWrapper .events .mySwiper .swiper {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    box-shadow: 0px 0px 16px var(--white-60);
    background-color: var(--white);
    cursor: pointer;
}

.eventSection .eventSectionWrapper .events .mySwiper .swiper .event {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 10px;
    box-sizing: border-box;
  
    /* border: 3px solid red; */
}

.eventSection .eventSectionWrapper .events .mySwiper .swiper .event .eventImg, .eventSection .eventSectionWrapper .events .mySwiper .swiper .event .eventTxt {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    position: relative;
}

.eventSection .eventSectionWrapper .events .mySwiper .swiper .event .eventImg > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
}

.eventSection .eventSectionWrapper .events .mySwiper .swiper .event .eventTxt .location {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
}

.eventSection .eventSectionWrapper .events .mySwiper .swiper .event .eventTxt .location > p {
    font-size: 14px;
    color: var(--black);
}

.eventSection .eventSectionWrapper .events .mySwiper .swiper .event .eventTxt .location > p .locationIcon {
    color: var(--primary-color);
    font-size: 15px;
}

.eventSection .eventSectionWrapper .events .mySwiper .swiper .event .eventTxt > h4 {
    margin: 0;
    padding: 0;
    font-size: 1.3rem;

}

.eventSection .eventSectionWrapper .events .mySwiper .swiper .event .eventTxt > p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    color: var(--black-60);
}

.eventSection .eventSectionWrapper .events .mySwiper .swiper .event .eventTxt > a {
    position: absolute;
    left: 0;
    bottom: 0;
    color: var(--primary-color);
    text-decoration: none;
    transition: all .6s ease-in-out;
}

.eventSection .eventSectionWrapper .events .mySwiper .swiper .event .eventTxt > a:hover {
    text-decoration: underline;
    transition: all .6s ease-in-out;
}

@media screen and (max-width: 480px) {
    .eventSection {
        width: 100%;
        height: fit-content;
        padding: 20px 0;
    }
    
    .eventSection .eventSectionWrapper .eventSectionHeading > p {
        font-size: 15px;
        margin: 0;
        width: 80%;
        text-align: center;
    }
    
    .eventSection .eventSectionWrapper .events {
        width: 100%;
        height: 80dvh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .eventSection .eventSectionWrapper .events .mySwiper {
        width: 100%;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
        /* display: flex; */
        /* align-items: center;
        justify-content: center; */
    }
    
    .eventSection .eventSectionWrapper .events .mySwiper .swiper {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        box-shadow: 0px 0px 16px var(--white-60);
    }
    
    .eventSection .eventSectionWrapper .events .mySwiper .swiper .event {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        padding: 10px;
        box-sizing: border-box;
      
        /* border: 3px solid red; */
    }
    
    .eventSection .eventSectionWrapper .events .mySwiper .swiper .event .eventImg, .eventSection .eventSectionWrapper .events .mySwiper .swiper .event .eventTxt {
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        justify-content: center;
        position: relative;
    }
    
    .eventSection .eventSectionWrapper .events .mySwiper .swiper .event .eventImg > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
    }
    
    .eventSection .eventSectionWrapper .events .mySwiper .swiper .event .eventTxt .location {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: space-between;
        margin: 0;
        padding: 0;
    }
    
    .eventSection .eventSectionWrapper .events .mySwiper .swiper .event .eventTxt .location > p {
        font-size: 14px;
        color: var(--black);
    }
    
    .eventSection .eventSectionWrapper .events .mySwiper .swiper .event .eventTxt .location > p .locationIcon {
        color: var(--primary-color);
        font-size: 15px;
    }
    
    .eventSection .eventSectionWrapper .events .mySwiper .swiper .event .eventTxt > h4 {
        margin: 0;
        padding: 0;
        font-size: 1.3rem;
    
    }
    
    .eventSection .eventSectionWrapper .events .mySwiper .swiper .event .eventTxt > p {
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: var(--black-60);
    }
    
    .eventSection .eventSectionWrapper .events .mySwiper .swiper .event .eventTxt > a {
        position: absolute;
        left: 0;
        bottom: 0;
        color: var(--primary-color);
        text-decoration: none;
        transition: all .6s ease-in-out;
    }
    
    .eventSection .eventSectionWrapper .events .mySwiper .swiper .event .eventTxt > a:hover {
        text-decoration: underline;
        transition: all .6s ease-in-out;
    }
}