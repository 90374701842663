.dashboardComponent {
    width: 100%;
    height: fit-content;
    display: flex;
    /* justify-content: center;
    align-items: center; */
    background-color: #464646;
    overflow: hidden;
}

.dashboardComponent .dashboardContent {
    width: 80%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    padding: 10px;
    box-sizing: border-box;
}