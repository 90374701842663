.dashboardMain {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
}

.dashboardMain .dashboardMainWrapper {
    width: 100%;
    height: 100%;
    column-count: 4;
  -moz-column-count: 4;
  -webkit-column-count: 4;
  column-width: 25%;
  -moz-column-width: 25%;
  -webkit-column-width: 25%;
}

.dashboardMain .dashboardMainWrapper .dashboardMainCard {
    padding: 20px;
    box-sizing: border-box;
    background-color: var(--blue);
    border-radius: 4px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.dashboardMain .dashboardMainWrapper .dashboardMainCard:nth-child(2),.dashboardMain .dashboardMainWrapper .dashboardMainCard:nth-child(3), .dashboardMain .dashboardMainWrapper .dashboardMainCard:nth-child(6), .dashboardMain .dashboardMainWrapper .dashboardMainCard:nth-child(7) {
    background-color: var(--lightBlue);
}

.dashboardMain .dashboardMainWrapper .dashboardMainCard .number {
    margin: 0;
    padding: 0;
    font-size: 3rem;
    letter-spacing: 1.3px;
}

.dashboardMain .dashboardMainWrapper .dashboardMainCard .what {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    margin-top: 16px;
    letter-spacing: 1.3px;
}