.mainGalleryWrapper {
    width: 100%;
    height: fit-content;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
}

.mainGalleryWrapper .pic {
    width: 30%;
    height: 50dvh;
    /* border: 3px solid red; */
    cursor: pointer;
    transition: all .6s ease-in-out;
    margin-bottom: 16px;
    box-shadow: 0px 0px 16px #0000004d;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 5px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 8px;
}

.mainGalleryWrapper .pic:hover {
    transform: scale(1.1,1.1);
}

.mainGalleryWrapper .pic > h2 {
    z-index: 1;
    color: #fff;
    font-weight: bold;
    font-size: 2rem;
    text-transform: capitalize;
    text-align: center;
}

.mainGalleryWrapper .pic > img {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    filter: brightness(50%);
    border-radius: 8px;

}

@media screen and (max-width: 430px) {
    .mainGalleryWrapper .pic {
        width: 90%;
        height: 40dvh;
        margin-bottom: 16px;
    }
}