.focusProjectComponent {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 80px;
    box-sizing: border-box;
}

.focusProjectComponent .focusProjectComponentWrapper {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.focusProjectComponent .focusProjectComponentWrapper > h1 {
    margin: 0;
    padding: 0;
    color: var(--primary-color);
    font-weight: bold;
    font-size: 2.5rem;
    text-transform: capitalize;
}

.focusProjectComponent .focusProjectComponentWrapper > p {
    font-size: 1rem;
    letter-spacing: 1.2px;
    width: 90%;
    margin: 0;
    padding: 0;
}

.events {
    width: 100%;
    height: 80dvh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.events .mySwiper {
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    /* display: flex; */
    /* align-items: center;
    justify-content: center; */
}

.events .mySwiper .swiper {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    box-shadow: 0px 0px 16px var(--white-60);
    background-color: var(--white);
    cursor: pointer;
}

.events .mySwiper .swiper .event {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 10px;
    box-sizing: border-box;
  
    /* border: 3px solid red; */
}

.events .mySwiper .swiper .event .eventImg, .events .mySwiper .swiper .event .eventTxt {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    position: relative;
}

.events .mySwiper .swiper .event .eventImg > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
}

.events .mySwiper .swiper .event .eventTxt .location {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
}

.eventSection .eventSectionWrapper .events .mySwiper .swiper .event .eventTxt .location > p {
    font-size: 14px;
    color: var(--black);
}

.events .mySwiper .swiper .event .eventTxt .location > p .locationIcon {
    color: var(--primary-color);
    font-size: 15px;
}

.events .mySwiper .swiper .event .eventTxt > h4 {
    margin: 0;
    padding: 0;
    font-size: 1.3rem;

}

.events .mySwiper .swiper .event .eventTxt > p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    color: var(--black-60);
}

.events .mySwiper .swiper .event .eventTxt > a {
    position: absolute;
    left: 0;
    bottom: 0;
    color: var(--primary-color);
    text-decoration: none;
    transition: all .6s ease-in-out;
}

.events .mySwiper .swiper .event .eventTxt > a:hover {
    text-decoration: underline;
    transition: all .6s ease-in-out;
}