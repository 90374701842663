.mission {
    width: 100%;
    height: 50dvh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 40px;
    box-sizing: border-box;
    background-color: var(--white);
}

.mission .missionWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    grid-area: 10px;
    align-items: center;
    justify-content: space-between;
}

.mission .missionWrapper .missioncard {
    width: 24%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0px 0px 16px #a1a1a156;
}

.mission .missionWrapper .missioncard > h4 {
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
    margin-bottom: 4px;
}

.mission .missionWrapper .missioncard > p {
    margin: 0;
    padding: 0;
    text-align: center;
}

.mission .missionWrapper .missioncard > ul {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: fit-content;
    padding: 0;
}

.mission .missionWrapper .missioncard > ul li {
    margin: 2px 0;
    text-align: unset;
    list-style-image: url(./../../assets/images/correct.png);
    list-style-type: georgian;
    letter-spacing: 0px;
    width: 100%;
    text-align-last: left;
    line-height: 1;
}

@media screen and (max-width: 480px) {
    .mission {
        height: fit-content;
        padding: 20px;
    }
    
    .mission .missionWrapper {
        flex-direction: column;
    }
    
    .mission .missionWrapper .missioncard {
        width: 94%;
        height: 50dvh;
        margin-bottom: 16px;
    }
}