.aboutMain {
    width: 100%;
    height: 60dvh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
}

.aboutMain .aboutMainWrapper {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.aboutMain .aboutMainWrapper .aboutMainImg, .aboutMain .aboutMainWrapper .aboutMainTxt {
    width: 50%;
    height: 100%;
}

.aboutMain .aboutMainWrapper .aboutMainImg .ImgWrapper {
    width: 100%;
    height: 100%;
}

.aboutMain .aboutMainWrapper .aboutMainImg .ImgWrapper > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.aboutMain .aboutMainWrapper .aboutMainTxt {
    padding-left: 20px;
    box-sizing: border-box;
}

.aboutMain .aboutMainWrapper .aboutMainTxt > h2 {
    margin: 0;
    padding: 0;
    margin-bottom: 8px;
    font-size: 2rem;
    color: var(--secondary-color);
}

.aboutMain .aboutMainWrapper .aboutMainTxt > p {
    margin: 8px 0;
    padding: 0;
    color: var(--black-60);
}

.aboutMain .aboutMainWrapper .aboutMainTxt .aboutMainBtn {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-top: 8px;
}

.aboutMain .aboutMainWrapper .aboutMainTxt .aboutMainBtn button {
    border: 2px solid var(--secondary-color);
    background-color: transparent;
    font-weight: bold;
    letter-spacing: 1.3px;
    border-radius: 8px;
    padding: 10px 20px;
    color: var(--secondary-color);
    cursor: pointer;
}

.aboutMain .aboutMainWrapper .aboutMainTxt .aboutMainBtn button.donate {
    background-color: var(--secondary-color);
    color: var(--white);
}

@media screen and (max-width: 480px) {
    .aboutMain {
        height: fit-content;
        margin-top: 16px;
    }
    
    .aboutMain .aboutMainWrapper {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
    }
    
    .aboutMain .aboutMainWrapper .aboutMainImg, .aboutMain .aboutMainWrapper .aboutMainTxt {
        width: 90%;
        height: 50dvh;
    }

    .aboutMain .aboutMainWrapper .aboutMainTxt {
        height: 100dvh;
    }

    .aboutMain .aboutMainWrapper .aboutMainImg {
        width: 100%;    
    }
    
    .aboutMain .aboutMainWrapper .aboutMainImg .ImgWrapper {
        width: 100%;
        height: 100%;
    }
    
    .aboutMain .aboutMainWrapper .aboutMainImg .ImgWrapper > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
    }
    
    .aboutMain .aboutMainWrapper .aboutMainTxt {
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        justify-content: center;
        padding-left: 0px;
        box-sizing: border-box;
    }   
}