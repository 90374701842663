.navbar {
    width: 100%;
    height: 70px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.navbar .navbarWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 80px;
    box-sizing: border-box;
}

.navbar .navbarWrapper .logo {
    width: fit-content;
    height: fit-content;
}

.navbar .navbarWrapper .logo > h1 {
    margin: 0;
    padding: 0;
    letter-spacing: 1.3px;
    color: var(--primary-color);
}

.navbar .navbarWrapper .linksWrapper {
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar .navbarWrapper .linksWrapper > ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar .navbarWrapper .linksWrapper > ul li {
    list-style: none;
    padding: 10px;
    box-sizing: border-box;
}

.navbar .navbarWrapper .linksWrapper > ul li > a {
    text-decoration: none;
    color: var(--black);
    font-size: 16px;
    margin: 0;
    padding: 0;
    letter-spacing: 1.3px;
    font-weight: 500;
}

.navbar .navbarWrapper .navBtn {
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: flex-end;
}

.navbar .navbarWrapper .navBtn > button {
    padding: 10px 20px;
    border: none;
    outline: none;
    background-color: var(--primary-color);
    color: var(--white);
    font-weight: bold;
    letter-spacing: 1.3px;
    border-radius: 8px;
}

.navbar .navbarWrapper .resBtn {
    background-color: transparent;
    padding: 4px 5px;
    box-sizing: border-box;
    border: 2px solid var(--primary-color);
    border-radius: 4px;
    cursor: pointer;
    display: none;
}

.navbar .navbarWrapper .resBtn > button {
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 16px;
    color: var(--primary-color);
    margin: 0;
    /* padding: 0; */
}

.navbar .resNav, .navbar .noResNav {
    width: 100%;
    height: calc(100dvh - 70px);
    background-color: var(--primary-color);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 1000;
    position: absolute;
    left: 0;
    top: 70px;
    transition: all .6s ease-in-out;
}

.navbar .resNav > ul, .navbar .noResNav > ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.navbar .noResNav {
    left: -100%;
    transition: all .6s ease-in-out;
}

.navbar .resNav > ul li, .navbar .noResNav > ul li {
    list-style: none;
    padding: 10px;
    box-sizing: border-box;
}

.navbar .resNav > ul li > a, .navbar .noResNav > ul li > a {
    text-decoration: none;
    color: var(--white);
    font-size: 16px;
    margin: 0;
    padding: 0;
    letter-spacing: 1.3px;
}

.navbar .resNav > ul li > a:hover, .navbar .noResNav > ul li > a:hover {
    color: var(--primary-color);
}

.navbar .resNav > ul li > a:active, .navbar .noResNav > ul li > a:active {
    color: var(--secondary-color);
}

.navbar .resNav > ul li > a:focus, .navbar .noResNav > ul li > a:focus {
    color: var(--primary-color);
}

.navbar .resNav > ul li > a:visited, .navbar .noResNav > ul li > a:visited {
    color: var(--white);
}

.navbar .resNav > ul li > a:link, .navbar .noResNav > ul li > a:link {
    color: var(--white);
}

.navbar .resNav > ul li > a:link:active, .navbar .noResNav > ul li > a:link:active {
    color: var(--secondary-color);
}

.navbar .resNav > ul li > a:link:focus, .navbar .noResNav > ul li > a:link:focus {
    color: var(--primary-color);
}

.navbar .resNav > ul li > a:link:visited, .navbar .noResNav > ul li > a:link:visited {
    color: var(--white);
}

.navbar .resNav > ul li > a:link:hover, .navbar .noResNav > ul li > a:link:hover {
    color: var(--white);
}

.navbar .resNav > ul li > a:link:visited, .navbar .noResNav > ul li > a:link:visited {
    color: var(--white);
}

.navbar .resNav > ul li > a:visited:active, .navbar .noResNav > ul li > a:visited:active {
    color: var(--secondary-color);
}

.navbar .resNav > ul li > a:visited:focus, .navbar .noResNav > ul li > a:visited:focus {
    color: var(--primary-color);
}

.navbar .resNav > ul li > a:visited:link, .navbar .noResNav > ul li > a:visited:link {
    color: var(--white);
}

@media screen and (max-width: 480px) {  
    .navbar .navbarWrapper {
        padding: 10px 20px;
    }
    
    .navbar .navbarWrapper .logo > h1 {
        margin: 0;
        padding: 0;
        letter-spacing: 1.3px;
    }
    
    .navbar .navbarWrapper .linksWrapper {
        display: none;
    }
    
    .navbar .navbarWrapper .navBtn {
        display: none;
    }
    
    .navbar .navbarWrapper .resBtn {
        display: flex;
    }
}