.eventSingle {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
}

.eventSingle .eventSingleWrapper {
    width: 90%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.eventSingle .eventSingleWrapper .event {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.eventSingle .eventSingleWrapper .event .eventImage {
    width: 100%;
    height: 50dvh;
    margin-bottom: 32px;
}

.eventSingle .eventSingleWrapper .event .eventImage > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.eventSingle .eventSingleWrapper .event .eventContent {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;

}

.eventSingle .eventSingleWrapper .event .eventContent .eventHeading {
    font-size: 2rem;
    letter-spacing: 1.3px;
    font-weight: bold;
    text-transform: capitalize;
    color: var(--blue);
    margin: 0;
    margin-bottom: 16px;
    padding: 0;
}

.eventSingle .eventSingleWrapper .event .eventContent .eventAbout {
    line-height: 1.3;
    letter-spacing: 1.2px;
    color: #000;
    font-size: 17px;
}

@media screen and (max-width: 430px) {
    
    .eventSingle .eventSingleWrapper .event .eventImage {
        margin-bottom: 16px;
    }
    
    .eventSingle .eventSingleWrapper .event .eventContent .eventHeading {
        margin-bottom: 8px;
    }
}