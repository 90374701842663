.otherPagesHero {
    width: 100%;
    height: 40dvh;
    height: 40vh;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    background: linear-gradient(var(--primary-color-60), var(--primary-color-60)), url(./../../assets/images/88339882_112961560310243_5820045440472580096_n.jpg) ;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    flex-direction: column;
}

.otherPagesHero .otherPagesHeroWrapper {
    width: 100%;
    height: calc(100% - 70px) ;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 80px;
    box-sizing: border-box;
}


.otherPagesHero .otherPagesHeroWrapper .otherPagesHeroHead {
    font-size: 2rem;
    margin: 0;
    font-weight: bold;
    letter-spacing: 1.3px;
    color: #fff;
    text-transform: capitalize;
}

@media screen and (max-width: 430px) {
.otherPagesHero {
    width: 100%;
    height: 40dvh;
    height: 40vh;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    background: linear-gradient(var(--primary-color-60), var(--primary-color-60)), url(./../../assets/images/88339882_112961560310243_5820045440472580096_n.jpg) ;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    flex-direction: column;
}

.otherPagesHero .otherPagesHeroWrapper {
    width: 100%;
    height: calc(100% - 70px) ;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 80px;
    box-sizing: border-box;
}


.otherPagesHero .otherPagesHeroWrapper .otherPagesHeroHead {
    font-size: 2rem;
    margin: 0;
    font-weight: bold;
    letter-spacing: 1.3px;
    color: #fff;
    text-transform: capitalize;
}
}