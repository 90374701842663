.mainContact {
    width: 100%;
    height: 70dvh;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 50px;
    box-sizing: border-box;
    background-color: #fff;
}

.mainContact .mainContactWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.mainContact .mainContactWrapper .mainContactlocation {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
}

.mainContact .mainContactWrapper .mainContactlocation .mainContactlocationCard {
    padding: 20px;
    box-sizing: border-box;
    border: 2px solid var(--primary-color);
    border-top: 4px solid var(--primary-color);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 16px;
}

.mainContact .mainContactWrapper .mainContactlocation .mainContactlocationCard .mainContactlocationIcons {
    color: var(--primary-color);
    font-size: 1.5rem;
    margin-right: 8px;
}

.mainContact .mainContactWrapper .mainContactlocation .mainContactlocationCard > p {
    margin: 0;
    font-size: 17px;
}

.mainContact .mainContactWrapper .mainContactFormWrapper {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mainContact .mainContactWrapper .mainContactFormWrapper .mainContactSocials {
    width: 30%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

.mainContact .mainContactWrapper .mainContactFormWrapper .mainContactSocials .mainContactSocial {
    padding: 20px;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--primary-color);
    border-top: 4px solid var(--primary-color);
    border-radius: 4px;
    margin: 16px 0;
}

.mainContact .mainContactWrapper .mainContactFormWrapper .mainContactSocials .mainContactSocial .mainContactSocialIcon {
    font-size: 1.5rem;
    margin-right: 8px;
    color: var(--primary-color);
}

.mainContact .mainContactWrapper .mainContactFormWrapper .mainContactSocials .mainContactSocial > p {
    margin: 0;
    font-size: 17px;
}

.mainContact .mainContactWrapper .mainContactFormWrapper .mainContactForm {
    width: 70%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mainContact .mainContactWrapper .mainContactFormWrapper .mainContactForm > form {
    width: 80%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--primary-color);
    border-top: 4px solid var(--primary-color);
    border-radius: 4px;
    padding: 30px;
    box-sizing: border-box;
}

.mainContact .mainContactWrapper .mainContactFormWrapper .mainContactForm > form .email {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.mainContact .mainContactWrapper .mainContactFormWrapper .mainContactForm > form .email .inputs, .mainContact .mainContactWrapper .mainContactFormWrapper .mainContactForm > form .inputs {
    width: 48%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.mainContact .mainContactWrapper .mainContactFormWrapper .mainContactForm > form .inputs {
    width: 100%;
}

.mainContact .mainContactWrapper .mainContactFormWrapper .mainContactForm > form .email .inputs label, .mainContact .mainContactWrapper .mainContactFormWrapper .mainContactForm > form .inputs  label {
    margin-bottom: 8px;
}

.mainContact .mainContactWrapper .mainContactFormWrapper .mainContactForm > form .email .inputs > input, .mainContact .mainContactWrapper .mainContactFormWrapper .mainContactForm > form .inputs > input, .mainContact .mainContactWrapper .mainContactFormWrapper .mainContactForm > form .inputs > textarea {
    width: 100%;
    padding: 8px;
    border: 2px solid var(--primary-color);
    border-radius: 4px;
    outline: none;
    box-sizing: border-box;
}

.mainContact .mainContactWrapper .mainContactFormWrapper .mainContactForm > form button {
    width: 100%;
    padding: 16px 32px;
    box-sizing: border-box;
    background-color: var(--primary-color);
    color: #fff;
    font-weight: bold;
    letter-spacing: 1.3px;
    border-radius: 4px;
    border: none;
    font-size: 17px;
    margin-top: 16px;
}

@media screen and (max-width: 1366px) {
    .mainContact {
        height: 110dvh;
        height: 110vh;
    }
}

@media screen and (max-width: 430px) {
    .mainContact {
        height: fit-content;
        padding: 20px;
    }
    
    .mainContact .mainContactWrapper .mainContactlocation {
        flex-direction: column;
        margin-bottom: 16px;
    }
    
    .mainContact .mainContactWrapper .mainContactlocation .mainContactlocationCard {
        padding: 20px;
        justify-content: flex-start;
        margin: 8px 0px;
        width: 100%;

    }
    
    .mainContact .mainContactWrapper .mainContactFormWrapper {
        flex-direction: column;
    }
    
    .mainContact .mainContactWrapper .mainContactFormWrapper .mainContactSocials {
        justify-content: center;
        align-items: center;
        width: 100%;
        /* padding: 20px; */
    }
    
    .mainContact .mainContactWrapper .mainContactFormWrapper .mainContactSocials .mainContactSocial {
        margin: 8px 0;
        width: 100%;
        box-sizing: border-box;
        align-items: flex-start;
        justify-content: flex-start;
    }
    
    .mainContact .mainContactWrapper .mainContactFormWrapper .mainContactForm {
        width: 100%;
    }
    
    .mainContact .mainContactWrapper .mainContactFormWrapper .mainContactForm > form {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
    }
    
    .mainContact .mainContactWrapper .mainContactFormWrapper .mainContactForm > form .email {
        flex-direction: column;
    }
    
    .mainContact .mainContactWrapper .mainContactFormWrapper .mainContactForm > form .email .inputs, .mainContact .mainContactWrapper .mainContactFormWrapper .mainContactForm > form .inputs {
        width: 100%;
    }
}