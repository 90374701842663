.sideNav {
    width: 20%;
    height: 100dvh;
    background-color: var(--lightBlue);
    padding: 30px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    position: relative;
}

.sideNav .sideNavWrapper {
    width: 90%;
    height: 100%;
    position: absolute;
    left: 10px;
    top: 10px;
}

.sideNav .dashboardMainBtn {
 width: 100%;
 padding: 16px 32px;
 box-sizing: border-box;
 background-color: var(--orange);
 font-size: 17px;
 letter-spacing: 3px;
 border-radius: 4px;
 font-weight: bold;
 box-shadow: 0px 0px 8px #96969681;
 border: none;
}

.sideNav .otherDashboardBtn {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin-top: 32px;
}

.sideNav .otherDashboardBtn .dashboardBtn, .sideNav .otherDashboardBtn .dashboardBtnLogOut {
    width: 100%;
    padding: 16px 32px;
    box-sizing: border-box;
    background-color: var(--blue);
    color: #fff;
    font-weight: bold;
    font-size: 17px;
    letter-spacing: 3px;
    border: none;
    border-radius: 4px;
    margin: 8px 0;
    cursor: pointer;
}

.sideNav .otherDashboardBtn .dashboardBtnLogOut {
    background-color: red;
}