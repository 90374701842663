.dashboardEmails {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: scroll;
}

.dashboardEmails .emailContents {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dashboardEmails .emailContents .heading {
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 1.3px;
    color: #000;
    margin: 0;
    margin-bottom: 16px;
}

.dashboardEmails .emailContents > table {
    width: 100%;
    height: fit-content;
    margin-bottom: 32px;
}

.dashboardEmails .emailContents > table thead tr th, .dashboardEmails .emailContents > table tbody tr td {
    border: 1px solid #000;
}

.dashboardEmails .emailContents .sendMail, .dashboardEmails .emailContents .sendMail .sendMailForm {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dashboardEmails .emailContents .sendMail .sendMailForm > form {
    width: 60%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dashboardEmails .emailContents .sendMail .sendMailForm .inputs {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.dashboardEmails .emailContents .sendMail .sendMailForm .inputs label {
    margin-bottom: 8px;
}

.dashboardEmails .emailContents .sendMail .sendMailForm .inputs > input, .dashboardEmails .emailContents .sendMail .sendMailForm .inputs > textarea {
    padding: 10px;
    box-sizing: border-box;
    outline: none;
    border: 1px solid #000;
    border-radius: 4px;
}

.dashboardEmails .emailContents .sendMail .sendMailForm .inputs > textarea {
    margin-bottom: 16px;
}

.dashboardEmails .emailContents .sendMail .sendMailForm > form > button {
    width: 100%;
    padding: 16px 32px;
    box-sizing: border-box;
    border: none;
    background-color: var(--blue);
    color: #fff;
    letter-spacing: 1.3px;
    text-transform: capitalize;
    border-radius: 4px;
}