.dashboardEvents {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    position: relative;
    overflow: scroll;
}

.dashboardEvents .showModalBtn, .dashboardEvents .modal .closeModalBtn {
    position: fixed;
    top: 50px;
    right: 50px;
    background-color: var(--orange);
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 15px;
    color: #000000;
    box-shadow: 0px 0px 16px #38383856;
    cursor: pointer;
}

.dashboardEvents .modal {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    z-index: 1;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    position: relative;
}

.dashboardEvents .modal .heading, .dashboardEvents .eventContents .heading {
    margin: 0;
    font-size: 1.5rem;
    letter-spacing: 1.3px;
    color: var(--blue);
    margin-bottom: 16px;
}

.dashboardEvents .modal > form {
    width: 50%;
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.dashboardEvents .modal > form .inputs {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 8px;
}

.dashboardEvents .modal > form .inputs label {
    margin-bottom: 8px;
}

.dashboardEvents .modal > form .inputs input, .dashboardEvents .modal > form .inputs textarea, form .inputs select {
    width: 100%;
    padding: 10px;
    border: 1.5px solid var(--primary-color);
    border-radius: 4px;
    outline: none;
    box-sizing: border-box;
}

.dashboardEvents .modal > form button {
    width: 100%;
    padding: 16px 32px;
    box-sizing: border-box;
    border: none;
    background-color: var(--primary-color);
    color: #fff;
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 1.3px;
    border-radius: 4px;
}

.dashboardEvents .eventContents {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}



.dashboardEvents .eventContents > table {
    width: 100%;
    height: 100%;
}

.dashboardEvents .eventContents > table thead, .dashboardEvents .eventContents > table thead tr {
    width: 100%;
}



.dashboardEvents .eventContents > table thead tr th {
    height: 50px;
}

.dashboardEvents .eventContents > table thead tr th, .dashboardEvents .eventContents > table tbody tr td {
    border: 1px solid #000;
    text-align: center;
    padding: 8px;
    box-sizing: border-box;
}

.dashboardEvents .eventContents > table tbody tr td > img {
    width: 70px;
    height: 70px;
}

.dashboardEvents .eventContents > table tbody tr td > button {
    background-color: transparent;
    border: none;
    color: #000;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 16px;
    cursor: pointer;
}

.dashboardEvents .deleteModal {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff4d;
    position: absolute;
    z-index: 1;
}

.dashboardEvents .deleteModal .deleteModalContent {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 4px;
    padding: 30px;
    box-sizing: border-box;
}

.dashboardEvents .deleteModal .deleteModalContent > h2 {
    margin: 0;
    padding: 0;
    font-size: 2rem;
    text-align: center;
    color: #000;
    margin-bottom: 16px;
    letter-spacing: 1.3px;
}

.dashboardEvents .deleteModal .deleteModalContent .deleteEventBtns {
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboardEvents .deleteModal .deleteModalContent .deleteEventBtns > button {
    padding: 16px 32px;
    box-sizing: border-box;
    border: none;
    color: #fff;
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 1.3px;
    margin: 8px;
    background-color: green;
}

.dashboardEvents .deleteModal .deleteModalContent .deleteEventBtns > button.deleteEvent {
    background-color: red;
}