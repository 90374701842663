.projectSection {
    width: 100%;
    height: 100dvh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    margin-top: 64px;
}

.projectSection .projectSectionWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.projectSection .projectSectionWrapper .projectSectionHeading {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 16px;
}

.projectSection .projectSectionWrapper .projectSectionHeading > h2 {
    margin: 0;
    padding: 0;
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 1.3px;
    margin-bottom: 8px;
    color: var(--primary-color);
}

.projectSection .projectSectionWrapper .projectSectionHeading > p {
    margin: 0;
    padding: 0;
    font-size: 15px;
    letter-spacing: 1.3px;
    width: 60%;
    text-align: center;
}

.projectSection .projectSectionWrapper .projects {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    flex-wrap: wrap;
    gap: 10px;
}

.projectSection .projectSectionWrapper .projects .project {
    background-color: var(--white);
    border-radius: 8px;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;
    height: 200px;
    cursor: pointer;
    transition: all 0.6s ease-in-out;
}

.projectSection .projectSectionWrapper .projects .project:hover {
    transform: scale(1.1, 1.1);
    transition: all 0.6s ease-in-out;
    background-color: var(--primary-color);
    z-index: 1;
}

.projectSection .projectSectionWrapper .projects .project:hover > h4 {
    color: var(--white);
    transition: all .6s ease-in-out;
}

.projectSection .projectSectionWrapper .projects .project:hover > p {
    color: var(--white);
    transition: all .6s ease-in-out;
}

.projectSection .projectSectionWrapper .projects .project .projectIcon {
    width: fit-content;
    height: fit-content;
    padding: 0;
    margin: 0;
}

.projectSection .projectSectionWrapper .projects .project .projectIcon > img {
    width: 50px;
    height: 50px;
    object-fit: contain;
    transition: all .6s ease-in-out;
}

.projectSection .projectSectionWrapper .projects .project:hover .projectIcon > img {
    filter: contrast(160%) saturate(3) grayscale(100%) brightness(0.25)  invert(100%) sepia(100%) hue-rotate(180deg) opacity(100%) ;
    transition: all .6s ease-in-out

}

.projectSection .projectSectionWrapper .projects .project > h4 {
    padding: 0;
    margin: 0;
    font-size: 1.5rem;
    transition: all .6s ease-in-out;

}

.projectSection .projectSectionWrapper .projects .project > p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    color: var(--black-60);
    text-align: center;
    transition: all .6s ease-in-out;

}

@media screen and (max-width: 480px) {
    .projectSection {
        height: fit-content;
        margin-top: 32px;
    }
    
    .projectSection .projectSectionWrapper .projectSectionHeading {
        margin-bottom: 8px;
    }
    
    .projectSection .projectSectionWrapper .projectSectionHeading > h2 {
        margin-bottom: 8px;

    }
    
    .projectSection .projectSectionWrapper .projectSectionHeading > p {
        width: 95%;
    }
    
    .projectSection .projectSectionWrapper .projects .project {
        width: 90%;
    }
}