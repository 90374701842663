.homeHero {
  width: 100%;
  height: 90dvh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 20%;
}

.homeHero .homeHeroWrapper {
  width: 100%;
  height: calc(100% - 70px);
  display: flex;
  flex-direction: column;
  position: relative;
}

.homeHero .homeHeroWrapper .mySwiper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.homeHero .homeHeroWrapper .welcome {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 80px;
  box-sizing: border-box;
}

.homeHero .homeHeroWrapper .mySwiper .welcome img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -999;
  position: absolute;
  left: 0;
  filter: brightness(0.2);
}

.homeHero .homeHeroWrapper .welcome p {
  margin: 0;
  padding: 0;
  color: var(--secondary-color);
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1.3px;
}

.homeHero .homeHeroWrapper .welcome > h2 {
  margin: 0;
  padding: 0;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 1.3px;
  color: var(--white);
}

.homeHero .homeHeroWrapper .welcome p.motto {
  text-transform: capitalize;
  font-style: italic;
}

.homeHero .homeHeroWrapper .welcome .heroBtns {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}

.homeHero .homeHeroWrapper .welcome .heroBtns button {
  padding: 10px 20px;
  box-sizing: border-box;
  border: 2px solid var(--primary-color);
  border-radius: 4px;
  background-color: transparent;
  color: var(--primary-color);
  font-weight: bold;
  letter-spacing: 1.3px;
  font-size: 16px;
  margin: 0px 8px;
  cursor: pointer;
}

.homeHero .homeHeroWrapper .welcome .heroBtns button:hover {
  background-color: var(--primary-color);
  color: #fff;
  transition: all 0.6s ease-in-out;
}

.homeHero .homeHeroWrapper .welcome .heroBtns button.donate {
  background-color: var(--primary-color);
  color: #fff;
}

.homeHero .homeHeroWrapper .welcome .heroBtns button.donate:hover {
  background-color: transparent;
  color: var(--primary-color);
  transition: all 0.6s ease-in-out;
}

.homeHero .homeHeroWrapper .achievements {
  width: 100%;
  /* height: 100px; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  gap: 20px;
  padding: 16px;
  box-sizing: border-box;
  bottom: -200px;
  z-index: 999;
}

.homeHero .homeHeroWrapper .achievements .achievement {
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  align-items: center;
  justify-content: center;
  width: 25%;
  background-color: var(--secondary-color);
  padding: 18px;
  box-sizing: border-box;
  border-radius: 4px;
  height: 300px;
  max-height: 450px;
  box-shadow: 0px 0px 8px #0000007a;
}

.homeHero .homeHeroWrapper .achievements .achievement > h3 {
  margin: 0;
  padding: 0;
  color: var(--primary-color);
  font-weight: bold;
  letter-spacing: 1.3px;
  margin-bottom: 4px;
  font-size: 1.5rem;
}

.homeHero .homeHeroWrapper .achievements .achievement > p {
  margin: 0;
  padding: 0;
  color: var(--white);
}

.homeHero .homeHeroWrapper .achievements .achievement > ul li {
  margin: 2px 0;
  text-align: unset;
  list-style-image: url(./../../assets/images/correct.png);
  list-style-type: georgian;
  letter-spacing: 0px;
  width: 100%;
  text-align-last: left;
  line-height: 1;
  color: var(--white);
}

@media screen and (max-width: 480px) {
    .homeHero {
        height: fit-content;
        margin-bottom: 32px;
    }

  .homeHero .homeHeroWrapper {
    padding: 0px 0px;
  }

  .homeHero .homeHeroWrapper .welcome {
  height:  80dvh;
  padding: 0px 10px;
}

  .homeHero .homeHeroWrapper .welcome > h2 {
    font-size: 2rem;
    text-align: center;
  }

    .homeHero .homeHeroWrapper .welcome > p {
        text-align: center;
    }

    .homeHero .homeHeroWrapper .achievements {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: 
  relative;
  left: 0;
  bottom: 0px;
    }

      .homeHero .homeHeroWrapper .achievements .achievement {
        width: 95%;
        height: 20%;
      }

  .homeHero .homeHeroWrapper .achievements .achievement > h3 {
    letter-spacing: 1.3px;
    margin-bottom: 0px;
    font-size: 1.3rem;
  }

  .homeHero .homeHeroWrapper .achievements .achievement > p {
    margin: 0;
    padding: 0;
    color: var(--white);
    font-size: 14px;
  }
}
