.volunteersSection {
    width: 100%;
    height: 75dvh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
    box-sizing: border-box;
}

.volunteersSection .volunteersSectionWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.volunteersSection .volunteersSectionWrapper .volunteersSectionHeading {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.volunteersSection .volunteersSectionWrapper .volunteersSectionHeading > h2 {
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 1.3px;
    margin: 0px 0px 8px 0%;
    padding: 0;
    color: var(--primary-color);
}

.volunteersSection .volunteersSectionWrapper .volunteersSectionHeading > p {
    font-size: 15px;
    margin: 0;
    width: 50%;
    text-align: center;
}

.volunteersSection .volunteersSectionWrapper .volunteers {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
}

.volunteersSection .volunteersSectionWrapper .volunteers .mySwiper {
    width: 100%;
    height: 100%;
    padding: 20px 0;
    box-sizing: border-box;   
}

.volunteersSection .volunteersSectionWrapper .volunteers .mySwiper .swiper {
    width: 100%;
    height: 100%;
}

.volunteersSection .volunteersSectionWrapper .volunteers .mySwiper .swiper .volunteer {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
}

.volunteersSection .volunteersSectionWrapper .volunteers .mySwiper .swiper .volunteer .volunteerImg {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 3px solid var(--primary-color);
}

.volunteersSection .volunteersSectionWrapper .volunteers .mySwiper .swiper .volunteer .volunteerImg > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.volunteersSection .volunteersSectionWrapper .volunteers .mySwiper .swiper .volunteer .volunteerTxt {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
}

.volunteersSection .volunteersSectionWrapper .volunteers .mySwiper .swiper .volunteer .volunteerTxt > h3 {
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
    text-align: center;
}

.volunteersSection .volunteersSectionWrapper .volunteers .mySwiper .swiper .volunteer .volunteerTxt > p {
    font-style: italic;
    margin: 0;
}

@media screen and (max-width: 480px) {
    .volunteersSection {
        height: 100dvh;
    }
    
    .volunteersSection .volunteersSectionWrapper .volunteersSectionHeading > p {
        width: 90%;
    }
    
    .volunteersSection .volunteersSectionWrapper .volunteers {
        height: 90%;
    }
    
    .volunteersSection .volunteersSectionWrapper .volunteers .mySwiper {
        width: 100%;
        height: 100%;
        padding: 20px 0;
        box-sizing: border-box;   
    }
    
    .volunteersSection .volunteersSectionWrapper .volunteers .mySwiper .swiper {
        width: 100%;
        height: 100%;
    }
    
    .volunteersSection .volunteersSectionWrapper .volunteers .mySwiper .swiper .volunteer {
        width: 100%;
        height: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 0;
    }
    
    .volunteersSection .volunteersSectionWrapper .volunteers .mySwiper .swiper .volunteer .volunteerImg {
        width: 150px;
        height: 150px;
    }
}