.partnersSection,
.partnersSection .partnersSectionWrapper {
  width: 100%;
  height: 50dvh;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 0px;
  box-sizing: border-box;
}

.partnersSection .partnersSectionWrapper {
  align-items: flex-start;
  padding: 0;
}

.partnersSection .partnersSectionWrapper .partnersHeading {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.partnersSection .partnersSectionWrapper .partnersHeading > h2 {
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 1.3px;
  margin: 0px 0px 8px 0%;
  padding: 0;
  color: var(--primary-color);
}

.partnersSection .partnersSectionWrapper .partnersHeading > p {
  font-size: 15px;
    margin: 0;
    width: 50%;
    text-align: center;
}

.partnersSection .partnersSectionWrapper .partnersCards {
  width: 100%;
  height: 30dvh;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 0px 20px; */
  box-sizing: border-box;
}

.partnersSection .partnersSectionWrapper .partnersCards .mySwiper {
  width: 100%;
  height: 100%;
}

.partnersSection .partnersSectionWrapper .partnersCards .mySwiper .swiperSlide {
  background: #fff;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  box-sizing: border-box;
  margin: 0;
}

.partnersSection
  .partnersSectionWrapper
  .partnersCards
  .mySwiper
  .swiperSlide
  .partner {
  /* width: 100%;
        height: 100%; */
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  margin: 0;
}

.partnersSection
  .partnersSectionWrapper
  .partnersCards
  .mySwiper
  .swiperSlide
  .partner
  .partnerImg {
  height: 70%;
}

.partnersSection
  .partnersSectionWrapper
  .partnersCards
  .mySwiper
  .swiperSlide
  .partner
  .partnerImg,
.partnersSection
  .partnersSectionWrapper
  .partnersCards
  .mySwiper
  .swiperSlide
  .partner
  .partnerTxt {
  padding: 10px;
  box-sizing: border-box;
}

.partnersSection
  .partnersSectionWrapper
  .partnersCards
  .mySwiper
  .swiperSlide
  .partner
  .partnerImg
  > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}


@media screen and (max-width: 480px) {
  .partnersSection,
  .partnersSection .partnersSectionWrapper {
    width: 100%;
    height: 60dvh;
    padding: 20px;
  }
  
  .partnersSection .partnersSectionWrapper .partnersHeading {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .partnersSection .partnersSectionWrapper .partnersHeading > h2 {
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 1.3px;
    margin: 0px 0px 8px 0%;
    padding: 0;
    color: var(--primary-color);
  }
  
  .partnersSection .partnersSectionWrapper .partnersHeading > p {
    font-size: 14px;
      margin: 0;
      width: 90%;
      text-align: center;
  }
  
  .partnersSection .partnersSectionWrapper .partnersCards {
    width: 100%;
    height: 40dvh;
  }
  
  .partnersSection .partnersSectionWrapper .partnersCards .mySwiper .swiperSlide {
    background: #fff;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    box-sizing: border-box;
    margin: 0;
  }
  
  .partnersSection
    .partnersSectionWrapper
    .partnersCards
    .mySwiper
    .swiperSlide
    .partner {
    /* width: 100%;
          height: 100%; */
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    margin: 0;
  }
  
  .partnersSection
    .partnersSectionWrapper
    .partnersCards
    .mySwiper
    .swiperSlide
    .partner
    .partnerImg {
    height: 70%;
  }
  
  .partnersSection
    .partnersSectionWrapper
    .partnersCards
    .mySwiper
    .swiperSlide
    .partner
    .partnerImg,
  .partnersSection
    .partnersSectionWrapper
    .partnersCards
    .mySwiper
    .swiperSlide
    .partner
    .partnerTxt {
    padding: 10px;
    box-sizing: border-box;
  }
  
  .partnersSection
    .partnersSectionWrapper
    .partnersCards
    .mySwiper
    .swiperSlide
    .partner
    .partnerImg
    > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}