:root {
  --primary-color: #0000b4;
  --primary-color-60: #0000b460;
  --secondary-color: #db8f00;
  --secondary-color-60: #db8f0060;
  --white: #f0f0f0;
  --white-60: #f0f0f080;
  --black: #02000c;
  --black-60: #02000c90;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Nexa', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  cursor: pointer;
  border-radius: 4px !important;
}