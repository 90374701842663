.editEvent {
    width: 100%;
    height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: absolute;
    background-color: #fff;
}

.editEvent .editEventContent {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.editEvent .editEventContent > h2 {
    margin: 0;
    padding: 0;
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 1.3px;
}

.editEvent .editEventContent > form {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
}

.editEvent .editEventContent > form > div {
    width: fit-content;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    margin-bottom: 8px;
    
}

.editEvent .editEventContent > form > div input {
    width: 100%;
    padding: 10px;
    border: 1px solid #000;
    border-radius: 4px;
    box-sizing: border-box;
}

.editEvent .editEventContent > form > button {
    width: 100%;
    padding: 16px 32px;
    box-sizing: border-box;
    border: none;
    background-color: green;
    color: #fff;
    font-weight: bold;
    letter-spacing: 1.3px;
    text-transform: capitalize;
    border-radius: 4px;
    margin-top: 8px;
}