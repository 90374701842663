.mainEventsSection {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
}

.mainEventsSection .mainEventsSectionWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.mainEventsSection .mainEventsSectionWrapper .mainEventCards {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    box-sizing: border-box;
    flex-wrap: wrap;
}

.mainEventsSection .mainEventsSectionWrapper .mainEventCards .mainEventCard {
    width: 23%;
    height: 50dvh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 0px 16px #4e4e4e4e;
    border-radius: 4px;
    margin: 16px 16px;
    padding: 10px;
    box-shadow: 0px 0px 16px var(--white-60);
    background-color: var(--white);
    box-sizing: border-box;
}

.mainEventsSection .mainEventsSectionWrapper .mainEventCards .mainEventCard .mainEventCardImg, .mainEventsSection .mainEventsSectionWrapper .mainEventCards .mainEventCard .mainEventCardTxt {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.mainEventsSection .mainEventsSectionWrapper .mainEventCards .mainEventCard .mainEventCardImg > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.mainEventsSection .mainEventsSectionWrapper .mainEventCards .mainEventCard .mainEventCardTxt {
    padding: 0px;
    box-sizing: border-box;
}

.mainEventsSection .mainEventsSectionWrapper .mainEventCards .mainEventCard .mainEventCardTxt .mainEventTop {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
}

.mainEventsSection .mainEventsSectionWrapper .mainEventCards .mainEventCard .mainEventCardTxt .mainEventTop .mainEventLocation {
    margin: 0;
    font-size: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin-bottom: 16px;
}

.mainEventsSection .mainEventsSectionWrapper .mainEventCards .mainEventCard .mainEventCardTxt .mainEventTop .mainEventLocation .mainEventLocationIcon {
    font-size: 20px;
    margin-right: 4px;
    color: var(--primary-color);
}

.mainEventsSection .mainEventsSectionWrapper .mainEventCards .mainEventCard .mainEventCardTxt .mainEventHeading {
    margin: 0;
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 1.1px;
    /* color: var(--primary-color); */
    text-transform: capitalize;
    margin-bottom: 16px;
}

.mainEventsSection .mainEventsSectionWrapper .mainEventCards .mainEventCard .mainEventCardTxt .mainEventBrief {
    margin: 0;
    line-height: 1.3;
    color: var(--black-60);
    font-size: 16px;
}

.mainEventsSection .mainEventsSectionWrapper .mainEventCards .mainEventCard .mainEventCardTxt > a {
    text-decoration: none;
    position: absolute;
    left: 10px;
    bottom: 10px;
    color: var(--primary-color);
}

.mainEventsSection .mainEventsSectionWrapper .mainEventCards .mainEventCard .mainEventCardTxt > a:hover {
    text-decoration: underline;
}

@media screen and (max-width: 1366px) {
    .mainEventsSection {
        height: fit-content;
    }
    
    .mainEventsSection .mainEventsSectionWrapper .mainEventCards .mainEventCard {
        width: 24%;
        height: 70dvh;
        height: 70vh;
        margin: 16px 16px;
    }

    
    .mainEventsSection .mainEventsSectionWrapper .mainEventCards .mainEventCard .mainEventCardTxt .mainEventHeading {
        margin-bottom: 8px;
    }
    
    .mainEventsSection .mainEventsSectionWrapper .mainEventCards .mainEventCard .mainEventCardTxt .mainEventBrief {
        line-height: 1;
        font-size: 14px;
    }
    
    .mainEventsSection .mainEventsSectionWrapper .mainEventCards .mainEventCard .mainEventCardTxt > a {
        bottom: 10px;
    }
}

@media screen and (max-width: 480px) {
    .mainEventsSection {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 20px;
        box-sizing: border-box;
        background-color: #fff;
    }
    
    .mainEventsSection .mainEventsSectionWrapper {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    .mainEventsSection .mainEventsSectionWrapper .mainEventCards {
        padding: 0px;
        flex-direction: column;
    }
    
    .mainEventsSection .mainEventsSectionWrapper .mainEventCards .mainEventCard {
        width: 90%;
        height: 80dvh;
        margin: 8px 0px;
        padding: 10px;
    }
}