.volunteerBanner {
    width: 100%;
    height: 20dvh;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 50px;
    box-sizing: border-box;
    background: linear-gradient(var(--primary-color-60), var(--primary-color-60)), url(./../../assets/images/88339882_112961560310243_5820045440472580096_n.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

}

.volunteerBanner .volunteerBannerWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 128px;
    box-sizing: border-box;
    flex-direction: column;
}


.volunteerBanner .volunteerBannerWrapper .volunteerBannerTxt {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.volunteerBanner .volunteerBannerWrapper .volunteerBannerTxt .volunteerBannerTxtHeading {
    margin: 0;
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 1.3px;
    color: #fff;
    text-transform: capitalize;
}

.volunteerBanner .volunteerBannerWrapper .volunteerBannerTxt .volunteerBannerTxtPara {
    letter-spacing: 1.1px;
    font-size: 15px;
    line-height: 1.3;
    color: #eeeeee;
    text-align: center;
    margin: 0px 0px 8px;
}

.volunteerBanner .volunteerBannerWrapper > button {
    padding: 10px 20px;
    box-sizing: border-box;
    border: none;
    border-radius: 4px;
    background-color: var(--primary-color);
    color: #fff;
    letter-spacing: 1.3px;
    font-weight: bold;
    font-size: 17px;
    margin: 0;
}

@media screen and (max-width: 1366px) {
    .volunteerBanner {
        height: 40dvh;
        height: 40vh;
    }
}

@media screen and (max-width: 430px) {
    .volunteerBanner {
        height: 50dvh;
        height: 50vh;
        padding: 20px;
    }
    
    .volunteerBanner .volunteerBannerWrapper {
        padding: 0px;
        flex-direction: column;
    }
    
    
    .volunteerBanner .volunteerBannerWrapper .volunteerBannerTxt {
        width: 100%;
    }
    
    .volunteerBanner .volunteerBannerWrapper .volunteerBannerTxt .volunteerBannerTxtPara {
        letter-spacing: 1px;
        line-height: 1;
    }
    
    .volunteerBanner .volunteerBannerWrapper > button {
        width: 100%;
    }
}