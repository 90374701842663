.dashboardBlogs {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  position: relative;
}

.dashboardBlogs .showModalBtn,
.dashboardBlogs .modal .closeModalBtn {
  position: absolute;
  top: 50px;
  right: 50px;
  background-color: var(--orange);
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px 15px;
  color: #fff;
  box-shadow: 0px 0px 16px #38383856;
  cursor: pointer;
}

.dashboardBlogs .modal {
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.dashboardBlogs .modal .heading,
.dashboardBlogs .eventContents .heading {
  margin: 0;
  font-size: 1.5rem;
  letter-spacing: 1.3px;
  color: var(--blue);
  margin-bottom: 16px;
}

.dashboardBlogs .modal > form {
  width: 50%;
  height: fit-content;
  display: flex;
  flex-direction: column;
}

.dashboardBlogs .modal > form .inputs {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 8px;
}

.dashboardBlogs .modal > form .inputs label {
  margin-bottom: 8px;
}

.dashboardBlogs .modal > form .inputs input,
.dashboardBlogs .modal > form .inputs textarea {
  width: 100%;
  padding: 10px;
  border: 1.5px solid var(--blue);
  border-radius: 4px;
  outline: none;
  box-sizing: border-box;
}

.dashboardBlogs .modal > form button {
  width: 100%;
  padding: 16px 32px;
  box-sizing: border-box;
  border: none;
  background-color: var(--blue);
  color: #fff;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 1.3px;
  border-radius: 4px;
}

.dashboardBlogs .eventContents {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dashboardBlogs .eventContents > table {
  width: 100%;
  height: fit-content;
}

.dashboardBlogs .eventContents > table thead,
.dashboardBlogs .eventContents > table thead tr {
  width: 100%;
}

.dashboardBlogs .eventContents > table thead tr th, .dashboardBlogs .eventContents > table tbody tr td {
  border: 1px solid #000;
}

.dashboardBlogs .eventContents > table tbody tr td > img {
  width: 70px;
  height: 70px;
}

.dashboardBlogs .deleteModal {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff4d;
  position: absolute;
  z-index: 1;
}

.dashboardBlogs .deleteModal .deleteModalContent {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  padding: 30px;
  box-sizing: border-box;
}

.dashboardBlogs .deleteModal .deleteModalContent > h2 {
  margin: 0;
  padding: 0;
  font-size: 2rem;
  text-align: center;
  color: #000;
  margin-bottom: 16px;
  letter-spacing: 1.3px;
}

.dashboardBlogs .deleteModal .deleteModalContent .deleteBlogBtns {
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboardBlogs .deleteModal .deleteModalContent .deleteBlogBtns > button {
  padding: 16px 32px;
  box-sizing: border-box;
  border: none;
  color: #fff;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 1.3px;
  margin: 8px;
  background-color: green;
}

.dashboardBlogs .deleteModal .deleteModalContent .deleteBlogBtns > button.deleteEvent {
  background-color: red;
}